import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import LoadingPage from './components/LoadingPage';

const App = () => {
  return (
    <div>
      <LoadingPage />
    </div>
  );
};

ReactDOM.render(<App />, document.querySelector('#root'));
