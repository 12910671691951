import SplitText from './SplitText';
import loadingIcon from '../icons/loading.svg';
import './LoadingPage.css';

const LoadingPage = () => {
  return (
    <div className="LoadingPage">
      <h1 style={{ size: 16 }}>
        <SplitText copy="subscribe.lk" role="heading" />
      </h1>
      <img src={loadingIcon} alt="logo" />
    </div>
  );
};

export default LoadingPage;
